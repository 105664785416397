import { isNull, isUndefined } from 'lodash';
import { POST_STATUS } from '@wix/communities-blog-universal/dist/src/constants/post';
import createI18nConfig from './create-i18n-config';
import { getTimeAgoFormat, isLessThanMinute } from './time-ago';
import { icuOptions } from '../constants/icu-options';
var _rtfFormatter;
var _language;
export var formatDate = function (date, lng) {
    var format = getTimeAgoFormat(date);
    return createI18nConfig().interpolation.format(date, format, lng);
};
export var formatDateICU = function (date, lng, rtfFormatter) {
    var format = getTimeAgoFormat(date);
    if ((!_rtfFormatter || lng !== _language) && rtfFormatter) {
        _rtfFormatter = rtfFormatter;
        _language = lng;
    }
    if (format === 'timeRelative' && _rtfFormatter) {
        return isLessThanMinute(date) ? 'few-seconds-ago' : _rtfFormatter(new Date(date));
    }
    var options = icuOptions.formats.date[format];
    return new Intl.DateTimeFormat(lng, options).format(new Date(date));
};
export var formatNumber = function (number, lng) {
    return createI18nConfig().interpolation.format(number, 'number', lng);
};
export var formatNumberICU = function (number, lng) {
    return new Intl.NumberFormat(lng).format(number);
};
var getPostFieldFormatters = function (useICU) {
    if (useICU === void 0) { useICU = false; }
    return [
        {
            fieldIn: 'firstPublishedDate',
            fieldOut: 'firstPublishedDateFormatted',
            formatter: useICU ? formatDateICU : formatDate,
        },
        {
            fieldIn: 'viewCount',
            fieldOut: 'viewCountFormatted',
            formatter: useICU ? formatNumberICU : formatNumber,
        },
        {
            fieldIn: 'likeCount',
            fieldOut: 'likeCountFormatted',
            formatter: useICU ? formatNumberICU : formatNumber,
        },
        {
            fieldIn: 'totalComments',
            fieldOut: 'totalCommentsFormatted',
            formatter: useICU ? formatNumberICU : formatNumber,
        },
    ];
};
export var localizePost = function (_a) {
    var post = _a.post, language = _a.language, _b = _a.useICU, useICU = _b === void 0 ? false : _b, _c = _a.rtfFormatter, rtfFormatter = _c === void 0 ? null : _c;
    if ((!_rtfFormatter || language !== _language) && rtfFormatter) {
        _rtfFormatter = rtfFormatter;
        _language = language;
    }
    getPostFieldFormatters(useICU).forEach(function (_a) {
        var fieldIn = _a.fieldIn, fieldOut = _a.fieldOut, formatter = _a.formatter;
        if (isUndefined(post[fieldOut])) {
            var value = post[fieldIn];
            if (isNull(value) || isUndefined(value)) {
                post[fieldOut] = null;
            }
            else {
                post[fieldOut] = formatter(value, language);
            }
        }
    });
    if (isUndefined(post.dateByStatus)) {
        post.dateByStatus = localizeDateByStatus(post, language, useICU);
    }
};
export var localizeLikeCount = function (post, language, useICU) {
    if (useICU === void 0) { useICU = false; }
    post.likeCountFormatted = useICU ? formatNumberICU(post.likeCount, language) : formatNumber(post.likeCount, language);
};
export function localizeDateByStatus(post, language, useICU) {
    var date = getDateByStatus(post);
    if (isUndefined(date) || isNull(date)) {
        return null;
    }
    return useICU ? formatDateICU(date, language) : formatDate(date, language);
}
function getDateByStatus(post) {
    var _a;
    switch (post.status) {
        case POST_STATUS.published:
            return post.firstPublishedDate;
        case POST_STATUS.unpublished:
            return (_a = post.draft) === null || _a === void 0 ? void 0 : _a.editedDate;
        default:
            return null;
    }
}
